import { useCallback, useContext, useEffect, useState } from "react";

import { optionsPOST, url_interna } from "../../../config/constants";
import { useVerifyResponse } from "hooks/otros/useVerifyReponse";
import moment from "moment";
import { notification, TableColumnsType } from "antd";

import { useLayout } from "hooks/otros/useLayout";

import { useSearchTableNew } from "hooks/otros/useSearchTableNew";
import { useExport } from "hooks/otros/useExport";
import { optionsGet } from "config/methods";

import { UserContext } from "context/UserContext";
type InventarioActual = {
  Codigo: number;
  Descripcion: string;
  Laboratorio: string;
  Cod_Barra: string;
  Costo: number;
  Unidad: number;
  Fraccion: number;
  CostoTotal:number;
  key: React.Key;
};
type DatosPeriodo = {
  label: string;
  value: string;
};
const breadcrumbs = [
  { Label: "Inventarios", Url: "/inventario" },
  { Label: "Inventario actual" },
];

//const breadcrumbs = [{ Label: "Inventarios", Url: "/inventario" }, { Label: "Productividad" }];

const openKeys = [breadcrumbs[0].Label];
export function useInventarioActual() {
  useLayout(breadcrumbs, openKeys, breadcrumbs[0].Label + "/" + breadcrumbs[1].Label);
  const [datos, setDatos] = useState<InventarioActual[]>([]);
  const [bodega, setBodega] = useState<number>();
  const [searchText] = useState("");
  const { userData } = useContext(UserContext);
  const [estadoBotones, setDataBoton] = useState<boolean>(false);
  const [dataEstado, setDataEstado] = useState<boolean>(false);
  const [filteredData, setFilteredData] = useState<InventarioActual[]>([]);
  const { getColumnSearchPropsNew } = useSearchTableNew<InventarioActual>();
  const [datosPeriodos, setDatosPeriodos] = useState<DatosPeriodo[]>([]);

  const { exportToExcel } = useExport();
  const { isError } = useVerifyResponse();

  const formateaCantidad = (cantidad: number) => {
    const totalCostoFormateado = (cantidad / 1).toLocaleString("de-DE", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return totalCostoFormateado;
  };

  const fechaInicio = moment().startOf("month");

  const getInventarioActual = async (codBodega: number) => {
    setDataEstado(false);
    setDataBoton(true);
    try {
      const res = await fetch(
        url_interna + "api/inventarios/obtener_inventario_actual_bodega",
        optionsPOST(
          JSON.stringify({
            codBodega: codBodega,
          }),
          {
            "api-token": "apitoken",
          }
        )
      );
      if (isError(res)) throw new Error();
      const respuesta = await res.json();

      if (respuesta.msg !== "err") {
        const respuestas: InventarioActual[] = respuesta.data;

        if (respuesta.data) {
          setDataBoton(false);
          setDataEstado(true);
          setDatos(
            respuestas.map(({ key, Codigo, Descripcion, Laboratorio, Cod_Barra, Costo, Unidad, Fraccion,CostoTotal }) => ({
              key,
              Codigo,
              Descripcion,
              Laboratorio,
              Cod_Barra,
              Costo,
              Unidad,
              Fraccion,
              CostoTotal
            }))
          );
        } else {
          setDataBoton(false);
          notification.warning({
            message: "Error",
            description: `No hay registros`,
            placement: "bottomRight",
          });
          setDataEstado(false);
        }
      } else {
        setDataBoton(false);
        setDataEstado(false);
        return { msg: "Ocurrio un error", status: false };
      }
    } catch (e) {
      setDataBoton(false);
      setDataEstado(false);
      return { msg: "no_conexion", status: false };
    }
  };
  const handleChangeButton = () => {
  
    if (bodega!==undefined)getInventarioActual(bodega);
    /* const inicioDelMes = mes.clone().startOf("month");
    const finDelMes = mes.clone().endOf("month");

    // Establece el día al final del mes

    if (inicioDelMes && finDelMes) {
      
    }*/
  };
  const calcularTotales = () => {
    let totalItems= datos.length;
    let costoTotal=0;
    datos.forEach((item) => {
      //totalItems += item;
      costoTotal += item.CostoTotal;
    });
    let unid = formateaCantidad(costoTotal);
   /* const promedioProduccion = formateaCantidad(totalUnidades / totalTiempo);
    let unid = formateaCantidad(totalUnidades);*/

    return { totalItems, unid };
  };
  /* useEffect(() => {
    const inicioDelMes = mes.clone().startOf("month");
    const finDelMes = mes.clone().endOf("month");

    // Establece el día al final del mes
    getBodegas(inicioDelMes, finDelMes);
  }, [mes, getBodegas]);*/

  const getDatosIniciales = useCallback(async () => {
    setDatosPeriodos([]);
    try {
      const codUsuario = userData?.id;
      let res;
      if(codUsuario===14){
        res = await fetch(url_interna + "api/inventarios/obtener_bodegas_piloto", optionsGet());
      }else if(codUsuario!==1731 && codUsuario!==14){
      res = await fetch(url_interna + "api/inventarios/obtener_bodegas", optionsGet());
      }else{
        res = await fetch(url_interna + "api/inventarios/obtener_bodegas_piloto", optionsGet());
      }
      if (isError(res)) throw new Error();
      const respuesta = await res.json();
      if (respuesta.msg !== "err") {
        const respuestas: DatosPeriodo[] = respuesta.data;
        if (respuestas.length > 0) {
          setDatosPeriodos(
            respuestas.map(({ label, value }) => ({
              label,
              value,
            }))
          );
        } else {
          setDatosPeriodos([]);
        }
      } else {
        return { msg: "Ocurrio un error", status: false };
      }
    } catch (e) {
      return { msg: "no_conexion", status: false };
    }
  }, [isError]);

  useEffect(() => {
    getDatosIniciales();
  }, [getDatosIniciales]);

  useEffect(() => {
    if (!searchText) {
      setFilteredData(datos); // Si no hay texto de búsqueda, muestra todos los datos
    } else {
      const filtered = datos.filter((item) => item.Descripcion.toLowerCase().includes(searchText.toLowerCase()));
      setFilteredData(filtered);
    }
  }, [searchText, datos]);

  const columns: TableColumnsType<InventarioActual> = [
    {
      title: "Código",
      dataIndex: "Codigo",
      key: "Codigo",

      className: "letraPequenaVisor2",
      ...getColumnSearchPropsNew("Codigo"),
    },
    {
      title: "Producto",
      dataIndex: "Descripcion",
      key: "Descripcion",

      ...getColumnSearchPropsNew("Descripcion"),
      className: "letraPequenaVisor2",
    },
    {
      title: "Laboratorio",
      dataIndex: "Laboratorio",
      key: "Laboratorio",
      filters: [...new Set(filteredData.map(({ Laboratorio }) => Laboratorio))].map((Laboratorio) => ({
        text: Laboratorio,
        value: Laboratorio,
      })),
      filterSearch: true,
      onFilter: (value, record) => value === record.Laboratorio,
      // ...getColumnSearchPropsNew("Descripcion"),
      className: "letraPequenaVisor2",
    },
    {
      title: "Código barras",
      dataIndex: "Cod_Barra",
      key: "Cod_Barra",
      align: "right",
      ...getColumnSearchPropsNew("Cod_Barra"),

      // ...getColumnSearchPropsNew("Descripcion"),
      className: "letraPequenaVisor2",
    },
    {
      title: "Costo",
      align: "right",
      dataIndex: "Costo",
      key: "Costo",
      className: "letraPequenaVisor2",
      render: (_, record) => (
        <span style={{ color: record.Costo === 0 ? "lightgray" : "black" }}>{formateaCantidad(record.Costo)}</span>
        // <span style={{ color: record.total === 0 ? "lightgray" : "black" }}>{record.total}</span>
      ),
    },
    {
      title: "Unidades",
      dataIndex: "Unidad",
      key: "Unidad",
      className: "letraPequenaVisor2",
      align: "right",
      sorter: (a, b) => a.Unidad - b.Unidad,
    },
    {
      title: "Fracciones",
      dataIndex: "Fraccion",
      key: "Fraccion",
      className: "letraPequenaVisor2",
      align: "right",
      sorter: (a, b) => a.Fraccion - b.Fraccion,
    },
    {
      title: "Costo total",
      align: "right",
      dataIndex: "CostoTotal",
      key: "CostoTotal",
      className: "letraPequenaVisor2",
      render: (_, record) => (
        <span style={{ color: record.CostoTotal === 0 ? "lightgray" : "black" }}>{formateaCantidad(record.CostoTotal)}</span>
        // <span style={{ color: record.total === 0 ? "lightgray" : "black" }}>{record.total}</span>
      ),
    },
  ];

  const handleClickDownloadData = () => {
    const datosParaExportar = filteredData.map(({ key, ...resto }) => resto);
    if (bodega !== undefined)
      exportToExcel(datosParaExportar, `Inventario actual FSG# ${bodega <= 16 ? bodega - 1 : bodega} `);
  };

  return {
    columns,
    filteredData,
    handleClickDownloadData,
    datosPeriodos,
    fechaInicio,
    setBodega,
    bodega,
    dataEstado,
    estadoBotones,
    handleChangeButton,
    calcularTotales
  };
}
