import { Col, Row, DatePicker, Table, Card, Modal, Form, Select, Button } from "antd";
import "moment/locale/es";

import moment from "moment";
import { useReporteInventario } from "hooks/inventarios/reportes/useReporteGeneral";
import { ButtonExcel } from "components/otros/buttons";
import { PdfViewer } from "components/otros/pdf-viewer";

export function ReporteGeneral() {
  const {
    columns,
    columsDatatableDetalle,
    columsDatatableDetalleUsuario,
    isModalOpen,
    isModalOpenUsuario,
    mes,
    setMes,
    datakey,
    datosInicial,
    openModalPdf,
    selecteds,
    setOpenModalPdf,
    /* detalle,
    detallePersonal,*/
    form,
    filteredData,
    estadoBotones,
    handleOk,
    handleCancel,
    handleClickDownloadData,
    handleClickDownloadDataPrincipal,
    dataEstado,
    detallePersonal,
    detalleUsuarios,
    handleCancelUsuario,
    guardar,
    isOkButtonDisabled,
    handleChangeButton,
  } = useReporteInventario();
  return (
    <Row style={{ padding: "16px", marginRight: "0px", backgroundColor: "#fff" }} gutter={[16, 16]}>
      <PdfViewer visible={openModalPdf} onCancel={() => setOpenModalPdf(false)} src={selecteds.enlacePdf} title="Archivo" />
      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
        <DatePicker
          value={mes}
          picker="month"
          placeholder="Seleccionar mes"
          format="MMMM [del] YYYY"
          onChange={(value) => setMes(value ?? moment())}
          inputReadOnly
        />
        <Button
          type="primary"
          style={{ marginLeft: "5px" }}
          //icon={<PoweroffOutlined />}
          loading={estadoBotones}
          disabled={mes === undefined}
          onClick={handleChangeButton}
        >
          Generar reporte
        </Button>
      </Col>
      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
        <Row gutter={[10, 10]} style={{ marginBottom: "20px", display: "flex", justifyContent: "flex-end" }}>
          <Col xs={24} md={10}>
            {mes !== undefined && <ButtonExcel buttonProps={{ onClick: handleClickDownloadDataPrincipal }}></ButtonExcel>}
          </Col>
        </Row>
      </Col>
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <Card>
          {dataEstado && (
            <Table
              columns={columns}
              dataSource={filteredData}
              scroll={{ x: true }}
              size="small"
              pagination={{ pageSize: 10, hideOnSinglePage: true, position: ["bottomCenter"] }}
            />
          )}
        </Card>
      </Col>
      <Modal
        footer={false}
        transitionName=""
        title="Detalles del inventario"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={"90wh"}
      >
        <Row gutter={[10, 10]} style={{ marginBottom: "20px", display: "flex", justifyContent: "flex-end" }}>
          <Col xs={24} md={4}>
            <ButtonExcel buttonProps={{ onClick: handleClickDownloadData }}></ButtonExcel>
          </Col>
        </Row>

        <Table
          style={{ marginBottom: "10px" }}
          pagination={false}
          loading={columsDatatableDetalle ? false : true}
          scroll={{ x: 900, y: "55vh" }}
          size="small"
          bordered
          columns={columsDatatableDetalle}
          dataSource={datosInicial.find((e) => e.key === datakey)?.laboratorios}
        />
        <Table
          pagination={false}
          loading={columsDatatableDetalleUsuario ? false : true}
          scroll={{ x: 900, y: "55vh" }}
          size="small"
          bordered
          columns={columsDatatableDetalleUsuario}
          dataSource={detallePersonal}
        />
      </Modal>
      <Modal
        title={"Añadir personal al inventario"}
        open={isModalOpenUsuario} // El Modal será visible cuando isModalOpen sea verdadero
        onCancel={handleCancelUsuario} // Cuando se cancele el Modal, se llamará a handleCancel
        onOk={guardar} // Cuando se presione OK, se llamará a handleOk
        width={500}
        okText={"Guardar"}
        okButtonProps={{ disabled: isOkButtonDisabled }}
      >
        <Row>
          <Col xs={24} xl={30}>
            <Form form={form}>
              <Form.Item label="Equipo de inventario" name="personal" rules={[{ required: true, message: "Seleccione una persona" }]}>
                <Select
                  //  onChange={handlePersonalChange}
                  options={detalleUsuarios}
                  mode="multiple"
                  allowClear
                  showSearch
                  optionFilterProp="label"
                  placeholder="Seleccione una opción"
                />
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Modal>
    </Row>
  );
}

export default ReporteGeneral;
