import { Col, Row, DatePicker, Table, Card, Modal, Select, Button, Spin } from "antd";
import "moment/locale/es";

import moment from "moment";
import { useControlInventarioEspecial } from "hooks/inventarios/inventarioEspecial/useControlInventario";

import { ButtonExcel } from "components/otros/buttons";
import { useVT } from "virtualizedtableforantd4";

export function ControlInventarioEspecial() {
  const [vt] = useVT(() => ({ scroll: { y: "55vh" } }), []);
  const {
    columns,
    mes,
    setMes,
    filteredData,
    datosPeriodos,
    datosTipos,
    handleCancelHistorico,
    isModalOpenHistorico,
    columsDatatableDetalleHistorico,
    datosHistoricos,
    estadoBotonFinalizar,
    /*handleClickDownloadDataHistorico,
    setRowClassName,*/
    setBodega,
    estadoBotones,
    bodega,
    dataEstado,
    handleChangeButton,
    handleChangeButtonTerminar,
    handleChangeButtonEmpezarReconteo,
    estadoBotonesFin,
    estadoBotonReconteo,
    handleChangeTipo,
    columnsReconteo,
    tipo,
    filteredDataReconteo,
    handleClickDownloadProductos
  } = useControlInventarioEspecial();
  return (
    <Row style={{ padding: "16px", marginRight: "0px", backgroundColor: "#fff" }} gutter={[16, 16]}>
      <Col xs={24} sm={12} md={12} lg={13} xl={13}>
        <Select
          placeholder="Selecione una bodega"
          style={{ width: 235, marginRight: "10px", marginTop: "0px" }}
          onChange={(value) => setBodega(value)}
          options={datosPeriodos}
        />
        <DatePicker
          value={mes}
          picker="month"
          placeholder="Seleccionar mes"
          format="MMMM [del] YYYY"
          onChange={(value) => setMes(value ?? moment())}
          inputReadOnly
        />
        <Button
          type="primary"
          style={{ marginLeft: "5px" }}
          //icon={<PoweroffOutlined />}
          loading={estadoBotones}
          disabled={bodega === undefined}
          onClick={handleChangeButton}
        >
          Generar reporte
        </Button>
      </Col>
      <Col xs={24} sm={12} md={12} lg={11} xl={11}>
        <Row gutter={[10, 10]} style={{ display: "flex", justifyContent: "flex-end" }}>
          {dataEstado && (
            <>
              <Select
                placeholder="Estado inventario"
                style={{ width: 150, marginRight: "10px", marginTop: "0px" }}
                onChange={(value) => handleChangeTipo(parseInt(value))}
                options={datosTipos}
              />
              <Button
                type="primary"
                style={{ marginLeft: "5px" }}
                loading={estadoBotonesFin}
                disabled={estadoBotonReconteo}
                onClick={handleChangeButtonEmpezarReconteo}
              >
                Empezar reconteo
              </Button>
              <Button
                type="primary"
                style={{ marginLeft: "5px" }}
                loading={estadoBotonesFin}
                disabled={estadoBotonFinalizar}
                onClick={handleChangeButtonTerminar}
              >
                Terminar inventario
              </Button>
            </>
          )}
        </Row>
      </Col>
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <Card>
          {!dataEstado && estadoBotones && (
            <div style={{ textAlign: "center" }}>
              <div style={{ margin: "0 auto" }}>
                <Spin />
              </div>
            </div>
          )}
          {dataEstado && tipo === 1 && (
            <>
              <Table
                columns={columns}
                dataSource={filteredData}
                components={vt}
                size="small"
                scroll={{ x: 900, y: "60vh" }}
                pagination={false}
                //pagination={{ pageSize: 10, hideOnSinglePage: true, position: ["bottomCenter"] }}
              />
              <div style={{ flexGrow: 1, display: "flex", justifyContent: "flex-end", marginTop: "15px" }}>
                <ButtonExcel buttonProps={{ onClick: handleClickDownloadProductos, style: { width: "auto", background: "green" } }}>Descargar</ButtonExcel>
              </div>
            </>
          )}
          {dataEstado && tipo === 2 && (
            <>
              <Table
                columns={columnsReconteo}
                dataSource={filteredDataReconteo}
                components={vt}
                size="small"
                scroll={{ x: 900, y: "60vh" }}
                pagination={false}
                //pagination={{ pageSize: 10, hideOnSinglePage: true, position: ["bottomCenter"] }}
              />
              <div style={{ flexGrow: 1, display: "flex", justifyContent: "flex-end", marginTop: "15px" }}>
                <ButtonExcel buttonProps={{ onClick: handleClickDownloadProductos, style: { width: "auto", background: "green" } }}>Descargar</ButtonExcel>
              </div>
            </>
          )}
        </Card>
      </Col>
      <Modal
        footer={false}
        transitionName=""
        title="Reporte inventario HandHeld"
        open={isModalOpenHistorico}
        onCancel={handleCancelHistorico}
        width={"90wh"}
      >
        <Table
          pagination={false}
          loading={columsDatatableDetalleHistorico ? false : true}
          // rowClassName={setRowClassName}
          scroll={{ x: 900, y: "55vh" }}
          size="small"
          bordered
          columns={columsDatatableDetalleHistorico}
          dataSource={datosHistoricos}
        />
      </Modal>
    </Row>
  );
}

export default ControlInventarioEspecial;
