import { Button, Col, Row, Table, Typography, DatePicker, Spin } from 'antd'
import { useLayout } from 'hooks/otros/useLayout'
import { FileExcelOutlined} from "@ant-design/icons"
import UseCuentaporPagar, { DataTypeCxP } from 'hooks/contabilidad/UseCuentaporPagar'

const { Title } = Typography

const breadcrumbs = [
    { Label: "Contabilidad", Url: "/contabilidad" },
    { Label: "Cuentas por Pagar", Url: "/contabilidad/cuentas-por-cobrar" },
  ]
  const openKeys = [breadcrumbs[0].Label, `${breadcrumbs[0].Label}/${breadcrumbs[1].Label}`]
  const selectKeySider = `${breadcrumbs[0].Label}/${breadcrumbs[1].Label}`

export default function CuentasPorPagar() {
    useLayout(breadcrumbs, openKeys, selectKeySider)

    const{columns, dataCxP, handleDownload, 
      Fecha, handleDateChange,dataEstado, disabledDate}=UseCuentaporPagar()
    
  return (
    <>
           <Row style={{ background: 'white', padding: '5px', paddingLeft:'10px'}}>
            <Col xs={24} sm={24} md={12}><Title level={3}>Cuentas por Pagar</Title></Col>
            <Col xs={24} sm={24} md={12}>
                <Row justify={"end"}>
                <Col sm={24} md={11}>
              <Row style={{ marginTop: "12px" }}>
                  <DatePicker format={'YYYY/MM/DD'} disabledDate={disabledDate} onChange={handleDateChange} defaultValue={Fecha} style={{ marginLeft: "2px", width: '100%', textAlign:'right' }}  />
              </Row>
            </Col>
            <Col style={{marginTop: '12px', marginLeft:'3px'}}>
            <Button
              type="primary"
              style={{ marginRight: "2px", marginTop: "1px", backgroundColor: "#00B050", borderColor: "#00B050" }}
              onClick={handleDownload}
            >
              Excel <FileExcelOutlined />
            </Button>
            </Col>
                </Row>
            </Col>
        </Row>
       
        {!dataEstado && ( 
          <Row style={{
            justifyContent: 'center', textAlign:'center',  display: 'flex',
          background: 'white', padding: '40px', height:'100%', paddingTop:'80px'}}>
                <Spin style={{fontSize:'20px'}}/>
            </Row>
          )}
         {dataEstado && (<Row style={{ alignItems:'center',background: 'white', padding: '5px', height:'100%'}}>         
            <Table style={{width:'100%', height:'100%'}} 
            columns={columns as DataTypeCxP[]}
            dataSource={dataCxP}  
              //rowClassName={(record, index) => 'miFilaSeleccionada'} 
            />
        </Row> )}
    </>
  )
}
