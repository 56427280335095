import { ColumnType, ColumnsType } from 'antd/lib/table'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { url, url_interna} from "config/constants"
import { optionsPost} from "config/methods"
import moment from 'moment'
import { useExport } from 'hooks/otros/useExport'
import { Button,  Input, InputRef, Space, Tag } from 'antd'
import {SearchOutlined} from "@ant-design/icons"
import { FilterConfirmProps } from 'antd/lib/table/interface'
import Highlighter from 'react-highlight-words'



export interface DataTypeCxP {
    key: React.Key
    cuenta: string
    Ruc: string
    Proveedor: string
    Tipo_Proveedor: string
    Naturaleza: string
    Tipo: string
    Documento: string
    Madurez_Cartera: string
    Fecha: Date
    Valor: Number
  }

type DataIndex = keyof DataTypeCxP


export default function UseCuentaporPagar() {

    const[dataCxP, setdataCxP]=useState<DataTypeCxP[]>([])
    const { exportToExcel } = useExport()
    const [searchText, setSearchText] = useState("");
    const [searchedColumn, setSearchedColumn] = useState("");
    const searchInput = useRef<InputRef>(null)
    const [Fecha, setFecha] = useState<moment.Moment>(moment())
    const disabledDate = (current: any) => {
      // Si la fecha actual es mayor a la fecha seleccionada, deshabilita la fecha
      return current && current > moment().endOf("day")
    }
    
  const [dataEstado, setDataEstado] = useState<boolean>(false);

    const handleDateChange = (dates: any, dateStrings: any) => {
      setFecha(dateStrings)
    }

    const handleSearch = (selectedKeys: string[], confirm: (param?: FilterConfirmProps) => void, dataIndex: DataIndex) => {
      confirm()
      setSearchText(selectedKeys[0])
      setSearchedColumn(dataIndex)
    }
  
    const handleReset = (clearFilters: () => void) => {
      clearFilters()
      setSearchText("")
    }
  
  
    const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<DataTypeCxP> => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
        <div style={{ padding: 8 }} onKeyDown={e => e.stopPropagation()}>
          <Input
            ref={searchInput}
            placeholder={`Buscar ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
            style={{ marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Buscar
            </Button>
            <Button onClick={() => clearFilters && handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              Limpiar
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                confirm({ closeDropdown: false })
                setSearchText((selectedKeys as string[])[0])
                setSearchedColumn(dataIndex)
              }}
            >
              Filtrar
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                close()
              }}
            >
              Cerrar
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
      onFilter: (value, record) =>
        record[dataIndex]
          .toString()
          .toLowerCase()
          .includes((value as string).toLowerCase()),
      onFilterDropdownOpenChange: visible => {
        if (visible) {
          setTimeout(() => searchInput.current?.select(), 100)
        }
      },
      render: text =>
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ""}
          />
        ) : (
          text
        ),
    })



    const columns: ColumnsType<DataTypeCxP> = [
/*         {
          title: "Tipo Proveedor",
          dataIndex: "Tipo_Proveedor",
          width: "200px",
          className:"fontsize",
        ...getColumnSearchProps('Tipo_Proveedor')
        }, */
        {
          title: "Naturaleza",
          dataIndex: "Naturaleza",
          width: "200px",
          className:"fontsize",
        ...getColumnSearchProps('Naturaleza')
        },
        {
          title: "Ruc",
          dataIndex: "Ruc",
          className:"fontsize",
          ...getColumnSearchProps('Ruc')

        },
        {
          title: "Proveerdor",
          dataIndex: "Proveedor",
          className:"fontsize",
        ...getColumnSearchProps('Proveedor')
        },
        {
          title: "Tipo",
          dataIndex: "Tipo",
          className:"fontsize",
        },
        {
          title: "Documento",
          dataIndex: "Documento",
          className:"fontsize",
          ...getColumnSearchProps('Documento')  
          
          //sorter: (a, b) => a.fecha_solicitud.getTime() - b.fecha_solicitud.getTime(),
        },
        {
          title: "Fecha",
          dataIndex: "Fecha",
          className:"fontsize",
          render:(fecha)=>{ return moment(fecha).format("DD/MM/YYYY") },
          sorter: (a, b) =>
            new Date(a.Fecha).getTime() - new Date(b.Fecha).getTime(),
        },
        {
          title: "Madurez Cartera",
          dataIndex: "Madurez_Cartera",
          className:"fontsize",
          render:(Madurez_Cartera)=>{ return <> <Tag color="green">{Madurez_Cartera+" días"}</Tag> </> },
          sorter: (a, b) =>Number(a.Madurez_Cartera)-Number(b.Madurez_Cartera),
        },
        {
          title: "Valor",
          dataIndex: "Valor",
          className:"fontsize",
          align:'right'
          //sorter: (a, b) => a.fecha_solicitud.getTime() - b.fecha_solicitud.getTime(),
        }
      ]

      const getCuentasporPagar =useCallback( async () => {
      try {
        setDataEstado(false);
        const res = await fetch(`${url_interna}api/contabilidad/cuentasporcobrar/get-CxP`, optionsPost({fecha: Fecha})).then((data) => data.json())
    
      let newdata:any=[]
      if(res){
        setDataEstado(true);
          newdata = Array.from(res).map((item: any, key: any) => ({
            key: key,
            cuenta: item.Cuenta,
            Ruc: item.Ruc,
            Proveedor: item.Proveedor,
            Tipo_Proveedor: item.Tipo_Proveedor,
            Naturaleza: item.Naturaleza,
            Tipo: item.Tipo,
            Documento: item.Documento,
            Fecha: item.Fecha,
            Madurez_Cartera: item.Madurez_Cartera,
            Valor: (item.Valor).toFixed(2),
            
        }))
       }
       setdataCxP(newdata)
       setDataEstado(true);
        
      } catch (error) {
        console.log(error)
      }
    },[Fecha ])

    const handleDownload = () => {    
      const dataExport = dataCxP.map((item)=>{
          return{
            "Cuenta": item.cuenta,
            "Tipo Proveedor": item.Tipo_Proveedor,
            "Naturaleza": item.Naturaleza,
            "Ruc": item.Ruc,
            "Proveedor": item.Proveedor,
            "Tipo": item.Tipo,
            "Fecha": moment(item.Fecha).format("DD/MM/YYYY"),
            "Madurez Cartera": item.Madurez_Cartera+" dias",
            "Documento": item.Documento,
            "Valor Total": item.Valor,
          }
      })
      exportToExcel(dataExport, `Reporte_Cuentas_por_Pagar${moment().format("DDMMYYYY")}`)
    }

      useEffect(() => {
        getCuentasporPagar()
      },[getCuentasporPagar])


  return {
    columns,
    dataCxP,
    handleDownload,
    Fecha,
    handleDateChange,
    dataEstado,
    disabledDate
  }
}


